import React, {useState} from 'react';

import styles from "./FormField.module.scss";

const FormField = ({value, setValue, type, name, placeholder, required, pattern}) => {
    const [errorText, setErrorText] = useState('');
    const [error, setError] = useState(false);

    const onBlur = () => {
        if (value) {
            if (pattern) {
                checkValidityByPattern();
            }
        } else {
            if (required) {
                setError(true);
                setErrorText('Поле обов`язкове');
            }
        }
    }

    const onChange = (inputValue) => {
        setValue(inputValue);

        if (inputValue && error) {
            setError(false);

            if (pattern) {
                checkValidityByPattern();
            }
        }
    }

    const checkValidityByPattern = () => {
        if (!new RegExp(pattern).test(value)) {
            setError(true);
            setErrorText('Неправильне значення');
        } else {
            setError(false);
        }
    };

    return (
        <div className={`${styles['form-field']} ${error ? styles['form-field_error'] : ''}`}>
            <input
                className={styles['form-field__input']}
                placeholder={placeholder}
                value={value}
                type={type}
                name={name}
                onBlur={onBlur}
                onFocus={onBlur}
                required={required}
                pattern={pattern}
                autoComplete="off"
                onChange={e => onChange(e.target.value)}
            />
            <span className={styles['form-field__error-text']}>{errorText}</span>
        </div>
    );
};

export default FormField;