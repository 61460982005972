import React from 'react';

import "./Burger.scss";

const Burger = ({openBurger, setOpenBurger}) => {
    return (
        <button
            onClick={() => setOpenBurger(state => !state)}
            className={`burger ${openBurger ? "burger_active" : ''}`}
        >
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
};

export default Burger;