import React from 'react';

import "./Section.scss";

const Section = (props) => {
    return (
        <section id={props.className} className={props.className + ' ' + (props.small ? "section section_small" : "section")}>
            {props.children}
        </section>
    );
};

export default Section;