import React from 'react';

import Slider from "react-slick";

import Section from "../../layout/Section/Section";
import Socials from "../../UI/Socials/Socials";
import Container from "../../layout/Container/Container";
import ScrollDown from "../../UI/ScrollDown/ScrollDown";

import image1 from "../../../assets/images/welcome/1.webp";
import image2 from "../../../assets/images/welcome/2.webp";
import image3 from "../../../assets/images/welcome/3.webp";
import image4 from "../../../assets/images/welcome/4.webp";

import "./Welcome.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Welcome = () => {
    const SLIDER_IMAGES = [
        {
            image: image1
        },
        {
            image: image2
        },
        {
            image: image3
        },
        {
            image: image4
        }
    ]

    const settings = {
        autoplay: true,
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        autoplaySpeed: 4000,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
    };

    return (
        <Section className={"welcome"} >
            <Slider className={"welcome__slider"} {...settings}>
                {SLIDER_IMAGES.map((item, index) =>
                    <img key={index} src={item.image} alt="Image"/>
                )}
            </Slider>

            <Container>
                <div className="welcome__inner">
                    <h1
                        data-aos="fade-up"
                    >
                        Legal Construct
                    </h1>

                    <h3
                        data-aos="fade-up"
                        data-aos-delay="200"
                    >
                        Юридична фірма
                    </h3>

                    <Socials/>
                </div>

                <ScrollDown/>
            </Container>
        </Section>
    );
};

export default Welcome;