import React from 'react';

import styles from "./Modal.module.scss";

const Modal = ({activeModal, closeModal, children}) => {
    return (
        <div className={activeModal ? `${styles['modal']} ${styles['modal_active']}` : `${styles['modal']}`} onClick={closeModal}>
            <div className={activeModal ? `${styles['modal__content']} ${styles['modal__content_active']}` : `${styles['modal__content']}`} onClick={e => e.stopPropagation()}>
                <button className={styles['modal__close']} onClick={closeModal}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.5799 0.420219C11.0197 -0.140073 10.1112 -0.140073 9.55095 0.420219L6.00027 3.97091L2.44958 0.420219C1.88929 -0.140073 0.980873 -0.140073 0.420581 0.420219C-0.139712 0.980511 -0.139711 1.88892 0.420581 2.44922L3.97127 5.9999L0.420464 9.55071C-0.139828 10.111 -0.139829 11.0194 0.420464 11.5797C0.980756 12.14 1.88917 12.14 2.44946 11.5797L6.00027 8.0289L9.55107 11.5797C10.1114 12.14 11.0198 12.14 11.5801 11.5797C12.1404 11.0194 12.1404 10.111 11.5801 9.55071L8.02926 5.9999L11.5799 2.44922C12.1402 1.88892 12.1402 0.980511 11.5799 0.420219Z" fill="var(--green)"/>
                    </svg>
                </button>

                {children}
            </div>
        </div>
    );
};

export default Modal;