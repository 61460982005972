import React, {useEffect} from "react";

import AOS from "aos";

import Bono from "./components/main/Bono/Bono";
import Team from "./components/main/Team/Team";
import Header from "./components/layout/Header/Header";
import Footer from "./components/layout/Footer/Footer";
import Ability from "./components/main/Ability/Ability";
import AboutUs from "./components/main/AboutUs/AboutUs";
import Welcome from "./components/main/Welcome/Welcome";
import Services from "./components/main/Services/Services";
import Portfolio from "./components/main/Portfolio/Portfolio";

import './App.scss';
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      once: true
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <Header/>

        <main>
          <Welcome/>

          <AboutUs/>

          <Services/>

          <Team/>

          <Ability/>

          <Portfolio/>

          <Bono/>
        </main>

      <Footer/>
    </div>
  );
}

export default App;
