import React from 'react';

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import SectionHeader from "../../layout/SectionHeader/SectionHeader";
import PortfolioGrid from "../../particles/PortfolioGrid/PortfolioGrid";

import "./Portfolio.scss";

const Portfolio = () => {
    return (
        <Section className={"portfolio"}>
            <Container>
                <SectionHeader text={"Кейси"}/>

                <PortfolioGrid/>
            </Container>
        </Section>
    );
};

export default Portfolio;