import React, {useState} from 'react';

import image1 from "../../../assets/images/portfolio/1.jpg";
import image2 from "../../../assets/images/portfolio/2.jpg";
import image3 from "../../../assets/images/portfolio/3.jpg";
import image4 from "../../../assets/images/portfolio/4.jpg";
import image5 from "../../../assets/images/portfolio/5.jpg";
import image6 from "../../../assets/images/portfolio/6.jpg";
import image7 from "../../../assets/images/portfolio/7.jpg";
import image8 from "../../../assets/images/portfolio/8.jpg";
import image9 from "../../../assets/images/portfolio/9.jpg";
import image10 from "../../../assets/images/portfolio/10.jpg";
import image11 from "../../../assets/images/portfolio/11.jpg";
import image12 from "../../../assets/images/portfolio/12.jpg";
import image13 from "../../../assets/images/portfolio/13.jpg";
import image14 from "../../../assets/images/portfolio/14.jpg";
import image15 from "../../../assets/images/portfolio/15.jpg";


import "./PortfolioGrid.scss";

const PortfolioGrid = () => {
    const gridItemsDefault = [
        {
            id: "1",
            info: "Введенно в експлуатацію житловий будинок площею до 500 м.кв.",
            category: "Житловий фонд",
            placement: "м. Черкаси",
            year: "2018",
            image: image1,
            filter: "life"
        },
        {
            id: "2",
            info: "Введено в експлуатацію перукарню площею 30 м.кв.",
            category: "Комерція",
            placement: "Жашківський р-н",
            year: "2022",
            image: image2,
            filter: "commerce"
        },
        {
            id: "3",
            info: "Введено в експлуатацію зерносушарку.",
            category: "Сільське господарство",
            placement: "Чорнобаївський р-н",
            year: "2018",
            image: image3,
            filter: "village"
        },
        {
            id: "4",
            info: "Ведено в експлуатацію адміністративно-складські приміщення площею до 500 м.кв.",
            category: "Комерція",
            placement: "м. Черкаси",
            year: "2021",
            image: image4,
            filter: "commerce"
        },
        {
            id: "5",
            info: "Введено в експлуатацію магазин площею до 200 м.кв.",
            category: "Комерція",
            placement: "Київська область",
            year: "2021",
            image: image5,
            filter: "commerce"
        },
        {
            id: "6",
            info: "Введено в експлуатацію склади площею до 200 м. кв.",
            category: "Комерція",
            placement: "Золотоніський p-н",
            year: "2021",
            image: image6,
            filter: "commerce"
        },
        {
            id: "7",
            info: "Введено в експлуатацію зерносушарку",
            category: "Сільське господарство",
            placement: "Золотоніський p-н",
            year: "2021",
            image: image7,
            filter: "village"
        },
        {
            id: "8",
            info: "Введено в експлуатацію житловий будинок до 300 м.кв.",
            category: "Житловий фонд",
            placement: "м. Черкаси",
            year: "2022",
            image: image8,
            filter: "life"
        },
        {
            id: "9",
            info: "Розроблено технічний паспорт для АЗС SOCAR",
            category: "Комерція",
            placement: "м. Черкаси",
            year: "2022",
            image: image9,
            filter: "commerce"
        },
        {
            id: "10",
            info: "Розроблено технічний паспорт для складського приміщення.",
            category: "Комерція",
            placement: "м. Золотоноша",
            year: "2021",
            image: image10,
            filter: "commerce"
        },
        {
            id: "11",
            info: "Введено в експлуатацію житловий будинок до 300 м.кв.",
            category: "Житловий фонд",
            placement: "Черкаський р-н",
            year: "2018",
            image: image11,
            filter: "life"
        },
        {
            id: "12",
            info: "Введено в експлуатацію житловий будинок до 300 м.кв.",
            category: "Житловий фонд",
            placement: "Черкаський р-н",
            year: "2018",
            image: image12,
            filter: "life"
        },
        {
            id: "13",
            info: "Введення в експлуатацію реконстукції з добудовою двох будинків на одній земельній ділянці.",
            category: "Житловий фонд",
            placement: "м. Черкаси",
            year: "2016",
            image: image13,
            filter: "life"
        },
        {
            id: "14",
            info: "Введеня в експлуатацію частини Черкаського зоопарку \"ROSHEN\" - рольєри та приміщення для лосів",
            category: "Соціальні проекти",
            placement: "м. Черкаси",
            year: "2016",
            image: image14,
            filter: "social"
        },
        {
            id: "15",
            info: "Введення в експлуатацію частини Черкаського зоопарку \"ROSHEN\" - земля Ведмедів та Вовків",
            category: "Соціальні проекти",
            placement: "м. Черкаси",
            year: "2018",
            image: image15,
            filter: "social"
        }
    ];

    const [gridItems, setGridItems] = useState(gridItemsDefault);

    const filtersDefault = [
        {
            name: "Всі",
            label: "all",
            isChecked: true
        },
        {
            name: "Комерція",
            label: "commerce",
            isChecked: false
        },
        {
            name: "Соціальні проекти",
            label: "social",
            isChecked: false
        },
        {
            name: "Житловий фонд",
            label: "life",
            isChecked: false
        },
        {
            name: "Сільське господарство",
            label: "village",
            isChecked: false
        }
    ];

    const [filters, setFilters] = useState(filtersDefault);

    const onFilter = event => {
        const {
            target: { value }
        } = event;

        setFilters(state =>
            state.map(f => {
                if (f.label === value) {
                    return {
                        ...f,
                        isChecked: true
                    };
                }

                return {
                    ...f,
                    isChecked: false
                };
            })
        )

        if (value === "all") {
            setGridItems(gridItemsDefault);
        } else {
            setGridItems(gridItemsDefault.filter(item => item.filter == value));
        }

    };

    return (
        <div className="portfolio-grid">
            <nav className="portfolio-grid__nav">
                <ul>
                    {filters.map(f => (
                        <li
                            className={f.isChecked === true ? "active" : ""}
                            key={`${f.label}_key`}
                        >
                            <input
                                id={f.label}
                                type="checkbox"
                                value={f.label}
                                onChange={onFilter}
                                checked={f.isChecked}
                            />

                            <label htmlFor={f.label}>
                                <h4>
                                    {f.name}
                                </h4>
                            </label>
                        </li>
                    ))}
                </ul>
            </nav>

            <div className="portfolio-grid__items">
                    {gridItems.map((card, index) => (
                        <div
                            data-aos="zoom-in"
                            data-aos-delay={index + "00"}
                            key={card.id}
                            className="portfolio-grid__item"
                        >
                            <div className="portfolio-grid__image">
                                <img src={card.image} alt={card.info}/>
                            </div>

                            <div className="portfolio-grid__info">
                                <h4>
                                    <strong>
                                        Категорія:
                                    </strong>

                                    <br/>

                                    {card.category}
                                </h4>

                                <p>
                                    <strong>
                                        Інформація:
                                    </strong>

                                    <br/>

                                    {card.info}
                                </p>

                                <p>
                                    <strong>
                                        Місцезнаходження:
                                    </strong>

                                    &nbsp;

                                    {card.placement}
                                </p>

                                <p>
                                    <strong>
                                        Рік:
                                    </strong>

                                    &nbsp;

                                    {card.year}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default PortfolioGrid;




