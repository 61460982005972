import React from 'react';

import "./ScrollDown.scss";

const ScrollDown = () => {
    return (
        <div className="scroll-down">
            <a href="#about-us">
                <div className="scroll-down__inner">
                    <div className="scroll-down__trigger">
                        <div></div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default ScrollDown;