import React from 'react';

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import SectionHeader from "../../layout/SectionHeader/SectionHeader";

import artem from "../../../assets/images/faces/artem.webp";
import daria from "../../../assets/images/faces/daria.webp";
import lyda from "../../../assets/images/faces/lyda.webp";
import tanya from "../../../assets/images/faces/tanya.webp";

import "./Team.scss";


const Team = () => {


    const TEAM = [
        {
            img: artem,
            name: 'Сучило Артем',
            position: 'Керуючий партнер, адвокат.',
            info: 'Практикує надання послуг в сфері нерухомості та будівництва з 2009 року. На його рахунку тисячі метрів квадратних введених в експлуатацію та десятки захищених Клієнтів в суді.'
        },
        {
            img: daria,
            name: 'Пацьора Дарія',
            position: 'Партнер, юрист.',
            info: 'Веде юридичну практику з 2017 року. Сфера діяльності - судові спори, нерухомість та будівництво, суміжні галузі. '
        },
        {
            img: lyda,
            name: 'Морозова Людмила',
            position: 'Партнер, юрист.',
            info: 'Юридичну практику розпочала в 2014 році з перервою на народження сина. Сфера діяльності -  нерухомість та будівництво, спадкування, інтелектуальна власність, сімейні та господарські правовідносини.'
        },
        {
            img: tanya,
            name: 'Зінченко Таня',
            position: 'Партнер, юрист.',
            info: 'Юридичну практику розпочала з 2019 року. Її тема - це відведення земельних ділянок та узаконення будівництва.'
        },
    ];


    return (
        <Section className={"team"}>
            <SectionHeader text={"Команда"}/>

            <Container>
                <div className="team-grid">
                    {TEAM.map((item, index) =>
                        <div
                            key={index}
                            className="team-grid__item"
                            data-aos="fade-up"
                            data-aos-delay={index + "00"}
                        >
                            <img src={item.img} alt={item.name}/>

                            <div className="team-grid__item-info">
                                <h3>
                                    {item.name}
                                </h3>

                                <h4>
                                    {item.position}
                                </h4>

                                <p>
                                    {item.info}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </Section>
    );
};

export default Team;