import React, {useRef, useState} from 'react';

import "./Nav.scss";
import Button from "../Button/Button";
import Modal from "../../particles/Modal/Modal";
import FormField from "../FormField/FormField";
import axios from "axios";

const Nav = ({openBurger}) => {
    const form = useRef(null);

    const [send, setSend] = useState(false);
    const [success, setSuccess] = useState(false);

    const [message, setMessage] = useState('')

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');

    const [activeModal, setActiveModal] = useState(false);

    const openModal = (event) => {
        event.preventDefault();
        setActiveModal(true);
    }

    const closeModal = () => {
        setName('');
        setPhone('');
        setComment('');
        setMessage('');
        setActiveModal(false);
        setSuccess(false);
        setSend(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (form.current.checkValidity()) {
            const data = new FormData(e.target);

            setSend(true);

            await axios.post(`/send.php`, data).then(() => {

                setMessage('Відправлено!')
                setSuccess('confirm')

                setTimeout(() => {
                    closeModal()
                }, 2000)
            })

                .catch((error) => {
                    setMessage(`${error}`)
                    setSuccess('denied')

                    setTimeout(() => {
                        closeModal()
                    }, 2000)
                })
        }
    }

    const navItems = [
        {
            link: "about-us",
            text: "Про нас"
        },
        {
            link: "portfolio",
            text: "Кейси"
        },
        {
            link: "team",
            text: "Команда"
        },
        {
            link: "ability",
            text: "Можливості"
        },
        {
            link: "services",
            text: "Послуги"
        }
    ]

    return (
        <nav className={`nav ${openBurger ? "nav_open" : ''}`}>
            <ul>
                {navItems.map((item, index) =>
                    <li
                        key={index}
                        data-aos="fade-left"
                        data-aos-delay={`${index + '00'}`}
                    >
                        <a href={`#${item.link}`}>
                            {item.text}
                        </a>
                    </li>
                )}
            </ul>

            <Button
                onClick={e => openModal(e)}
            >
                Консультація
            </Button>


            {activeModal && (
                <Modal
                    activeModal={activeModal}
                    closeModal={closeModal}
                >
                    <h3 style={{textAlign: "center", color: "var(--green)"}}>
                        Надішліть ваше звернення
                    </h3>

                    <form
                        ref={form}
                        onSubmit={e => submitForm(e)}
                        autoComplete="off"
                        className={send ? "form form_send" : "form"}
                    >

                        {send && (
                            <div
                                className={`form__animate ${success === 'confirm' ? 'form__animate_success' : ''} ${success === 'denied' ? 'form__animate_denied' : ''}`}>
                                <span></span>

                                <h4>
                                    {message}
                                </h4>
                            </div>
                        )}

                        <FormField value={name} setValue={setName} type={"text"} name={"name"} placeholder={"П.І.Б."}
                                   required={true}/>

                        <FormField value={phone} setValue={setPhone} type={"tel"} name={"tel"}
                                   placeholder={"Ваш номер телефону"} required={true}
                                   pattern={"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"}/>

                        <FormField value={comment} setValue={setComment} type={"text"} name={"comment"}
                                   placeholder={"Ваше звернення"}/>

                        <Button
                            style={{color: "var(--green", borderColor: "var(--green", margin: "2.5rem auto 0"}}
                        >
                            Відправити
                        </Button>
                    </form>
                </Modal>
            )}
        </nav>
    );
};

export default Nav;