import React from 'react';

import "./SectionHeader.scss";

const SectionHeader = (props) => {
    return (
        <div
            data-aos="fade-up"
            className="section-header"
        >
            <span className="section-header__big-text">
                {props.text}
            </span>

            <h2>
                {props.text}
            </h2>
        </div>
    );
};

export default SectionHeader;