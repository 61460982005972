import React, {useEffect, useState} from 'react';

import Nav from "../../UI/Nav/Nav";
import Burger from "../../UI/Burger/Burger";
import Container from "../Container/Container";

import logo from "../../../assets/images/icons/logo.svg"

import "./Header.scss";

const Header = () => {
    const [scroll, setScroll] = useState(false);

    const [openBurger, setOpenBurger] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    return (
        <header className={scroll ? "header header_scrolled" : "header"}>
            <Container>
                <div
                    data-aos="fade-right"
                    className="header__logo">
                    <a href="#">
                        <img src={logo} alt="Logo"/>
                    </a>
                </div>

                <Nav
                    openBurger={openBurger}
                />

                <Burger
                    openBurger={openBurger}
                    setOpenBurger={setOpenBurger}
                />
            </Container>
        </header>
    );
};

export default Header;