import React from 'react';

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import SectionHeader from "../../layout/SectionHeader/SectionHeader";

import "./Bono.scss";

const Bono = () => {
    return (
        <Section className="bono">
            <Container>
                <div className="bono__inner">
                    <div
                        data-aos="fade-right"
                        className="bono__heading">
                        <h2>
                            PRO BONO.
                        </h2>
                    </div>

                    <div
                        data-aos="fade-left"
                        className="bono__first-info">
                        <h3>
                            Існує таке поняття “Pro bono”.
                        </h3>

                        <h4>
                            Воно охарактеризовує послуги, що надаються добровільно і безкоштовно, для користі громади, особливо для тих осіб, які не мають коштів для оплати цих послуг (Вікіпедія)
                        </h4>

                        <p>
                            <b>
                                Правники в більшості штатів США серед етичних засад діяльності, встановлених American Bar Association, мають обов'язок щорічно відпрацювати щонайменше 50 годин послугами pro bonо.
                                Звісно ми не в США і в нас немає таких обов’язків, але нам дуже хочеться привнести таку традицію в м. Черкаси.
                            </b>
                        </p>

                        <p>
                            Ми довго думали, які саме проекти візьмемо на Pro bono обслуговування.
                        </p>

                        <p>
                            Будемо чесними: це було важко!
                        </p>
                    </div>

                    <div
                        data-aos="fade-up"
                        className="bono__second-info">
                        <h4>
                            Але команда дійшла до єдиного знаменника - <strong>це проекти в сфері нерухомості та будівництва, пов’язані з екологією та захистом природи.</strong>
                        </h4>


                        <p>
                            Тому ми зобов’язуємось Вас проконсультувати безкоштовно щодо зазначених питань, <b>але лише у сфері нерухомості і будівництва</b> (в цьому ми спеціалісти та впевнені в своїх знаннях і готові ними ділитись).
                        </p>
                    </div>

                    <div className="bono__third-info">
                        <div
                            data-aos="fade-right"
                            className="bono__binary">
                            <h4>
                                Для прикладу це можуть бути питання:
                            </h4>

                            <ul>
                                <li>
                                    будівництва
                                </li>

                                <li>
                                    введення в експлуатацію
                                </li>

                                <li>
                                    поділу/виділу
                                </li>

                                <li>
                                    отримання дозвільних документів
                                </li>

                                <li>
                                    реєстрації права власності і т.д.
                                    щодо об’єктів
                                </li>

                                <li>
                                    із використанням відновлювальних джерел енергії
                                </li>

                                <li>
                                    будинку з екологічних матеріалів
                                </li>

                                <li>
                                    притулку для тварин
                                </li>

                                <li>
                                    об’єкту зеленого туризму
                                </li>

                                <li>
                                    місць рекреаційного призначення
                                </li>

                                <li>
                                    дендропарків
                                </li>

                                <li>
                                    …перелік не є вичерпним
                                </li>
                            </ul>
                        </div>

                        <div
                            data-aos="fade-left"
                            className="bono__binary">
                            <h4>
                                Отже ще раз про умови:
                            </h4>

                            <p>
                                у Вас є питання в сфері будівництва і нерухомості в ході реалізації проекту, який підпадає під екологічний продукт, або має на меті підтримку природи - <b>у нас є для Вас безкоштовна консультація</b>.
                            </p>

                            <p>
                                У випадку, якщо Ваш проект буде значущим, цікавим, суспільно-необхідним (залишаємо за собою право оцінки) - Legal Construct може вести Ваш проект безкоштовно (за виключення будь-яких фактичних витрат).
                            </p>

                            <p>
                                Звісно юристи Legal Construct залишають за собою право вибору: чи підходите Ви під зазначені вище критерії, оскільки найкращі наміри може погубити звичайна людська жадібність та хитрість.
                            </p>

                            <h4>
                                Ми поважаємо волонтерство і хочемо, щоб люди, в яких є бажання якісно змінити світ навколо, відчували підтримку у їхній благій справі.
                            </h4>

                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default Bono;