import React from 'react';

import SocialLink from "../SocialLink/SocialLink";

import inst from "../../../assets/images/icons/socials/inst.svg"
import fb from "../../../assets/images/icons/socials/fb.svg"

import "./Socials.scss";

const SOCIALS = [
    {
        link: "https://instagram.com/legal_construct",
        icon: inst
    },
    {
        link: "https://www.facebook.com/LegalConstruct/",
        icon: fb
    }
]

const Socials = () => {
    return (
        <ul className="socials">
            {SOCIALS.map((socialItem, index) =>
                <SocialLink
                    key={index}
                    link={socialItem.link}
                    icon={socialItem.icon}
                    animation={"fade-up"}
                    delay={`${("1" + index) + "00"}`}
                />
            )}
        </ul>
    );
};

export default Socials;