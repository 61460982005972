import React from 'react';

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";

import abilityImage from "../../../assets/images/backgrounds/sections/ability.webp"

import "./Ability.scss";

const Ability = () => {
    const ABILITY = [
        {
            ability: "Представництво в судах"
        },
        {
            ability: "Медіація"
        },
        {
            ability: "Створення технічних паспортів "
        },
        {
            ability: "Послуги у сфері технічного нагляду"
        },
        {
            ability: "Претензійна діяльність"
        },
        {
            ability: "Досудове врегулювання"
        },
        {
            ability: "Консультації"
        },
        {
            ability: "Будівництво"
        },
        {
            ability: "Супровід купівлі-продажу/оренди нерухомості"
        },
        {
            ability: "Та все, що має відношення до нерухомості та будівництва"
        }
    ]

    return (
        <Section className={"ability"}>
            <Container>
                <div className="ability__info">
                    <h2
                        data-aos="fade-up"
                    >
                        Наші можливості
                    </h2>

                    <ul>
                        {ABILITY.map((item, index) =>
                            <li
                                key={index}
                                data-aos="fade-right"
                                data-aos-delay={(("1" + index) + "00")}
                            >
                                {item.ability}
                            </li>
                        )}
                    </ul>

                    <h3
                        data-aos="fade-up"
                        data-aos-delay="200"
                    >
                        Абсолютно все, починаючи від Вашого задуму до реєстрації права власності Ви можете довірити нам.
                    </h3>
                </div>

                <div
                    data-aos="fade-left"
                    className="ability__image"
                >
                    <img src={abilityImage} alt="Legal Construct"/>
                </div>
            </Container>
        </Section>
    );
};

export default Ability;