import React from 'react';

import Nav from "../../UI/Nav/Nav";
import Container from "../Container/Container";

import "./Footer.scss";

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <div className="footer__info">
                    <h4>
                       Контакти:
                    </h4>

                    <ul className="footer__contacts">
                        <li>
                            <a href="tel: +380 63 4391 787">+380 63 4391 787</a>
                        </li>

                        <li>
                            <a href="tel: +380 63 948 0459">+380 63 948 0459</a>
                        </li>

                        <li>
                            <a href="mailto: legalconstruct.lc@gmail.com">legalconstruct.lc@gmail.com</a>
                        </li>
                    </ul>

                    <Nav/>
                </div>

                <div className="footer__map">
                    <iframe
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2593.893848416765!2d32.04547791569534!3d49.448723279349686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14ded14d94c51%3A0xc5d2cce6ee91f44a!2z0K7RgNC40LTQuNGH0L3QsCDRhNGW0YDQvNCwICJMZWdhbCBDb25zdHJ1Y3QiOiDRjtGA0LjRgdGC0Lgg0Lcg0L3QtdGA0YPRhdC-0LzQvtGB0YLRliDRgtCwINCx0YPQtNGW0LLQvdC40YbRgtCy0LAg0LwuINCn0LXRgNC60LDRgdC4INGC0LAg0KfQtdGA0LrQsNGB0YzQutCwINC-0LHQuy4sINGD0LfQsNC60L7QvdC10L3QvdGPINC_0LXRgNC10L_Qu9Cw0L3Rg9Cy0LDQvdC90Y8sINGB0LDQvNC-0LHRg9C00ZbQsiAv0LLQstC10LTQtdC90L3RjyDQsiDQtdC60YHQv9C70YPQsNGC0LDRhtGW0Y4g0L7QsSfRlNC60YLRltCyLyDQutC-0LzQtdGA0YbRltC50L3QsCDQvdC10YDRg9GF0L7QvNGW0YHRgtGM!5e0!3m2!1sru!2sua!4v1652036292250!5m2!1sru!2sua"
                        allowFullScreen="" loading="lazy"></iframe>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;