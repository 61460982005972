import React from 'react';

import "./SocialLink.scss";

const SocialLink = ({link, icon, animation, delay}) => {
    return (
        <li
            data-aos={animation}
            data-aos-delay={delay}
            className="social-link"
        >
            <a href={link} target="_blank">
                <img src={icon} alt="Icon"/>
            </a>
        </li>
    );
};

export default SocialLink;