import React from 'react';

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";

import aboutUsImage from "../../../assets/images/backgrounds/sections/about-us.webp"

import "./AboutUs.scss"

const AboutUs = () => {
    return (
        <Section className={"about-us"}>
            <Container>
                <div
                    data-aos="fade-right"
                    className="about-us__image"
                >
                    <img src={aboutUsImage} alt="Legal Construct"/>
                </div>

                <div className="about-us__info">
                    <h2
                        data-aos="fade-left"
                        data-aos-delay="200"
                    >
                        Про нас
                    </h2>

                    <h3
                        data-aos="fade-left"
                        data-aos-delay="300"
                    >
                        Legal Construct  - це про будівництво та нерухомість, а не лише про юридичні послуги.
                    </h3>

                    <p
                        data-aos="fade-left"
                        data-aos-delay="400"
                    >
                        Ми команда, яка може супроводжувати Клієнта починаючи з відведення земельної ділянки до здачі в експлуатацію готового об’єкту нерухомості.
                    </p>

                    <p
                        data-aos="fade-left"
                        data-aos-delay="500"
                    >
                        Ми потрібні при переплануванні, при продажу нерухомості, реєстрації права власності, створенні технічного паспорту, при побудові нерухомості або ж її знесенні.
                    </p>

                    <h4
                        data-aos="fade-left"
                        data-aos-delay="600"
                    >
                        Ми не просто юристи, ми - землевпорядники, медіатори, сертифіковані техніки.
                    </h4>
                </div>
            </Container>
        </Section>
    );
};

export default AboutUs;